import { locationField } from 'common/constants'

export const whoAreNearField = 'whoAreNear'
export const interestsField = 'interests'
export const lookForField = 'lookFor'
export const targetField = 'target'
export const purposeField = 'purpose' // TODO purpose в урле, на самом деле название поля target (targetField)
export const childrenField = 'children'
export const weightField = 'weight'
export const heightField = 'height'
export const educationField = 'education'
export const physiqueField = 'physique' // constitutionField
export const constitutionField = 'constitution'
export const orientationField = 'orientation'
export const signField = 'sign'
export const appearanceField = 'appearance' // raceField
export const raceField = 'race'
export const languageField = 'language' // languageApiField
export const languageApiField = 'lang'
export const smokeField = 'smoke'
export const drinkField = 'drink'
export const circumstanceField = 'circumstance'
export const homeField = 'home'
export const sexOftenField = 'sexOften'
export const sexRoleField = 'sexRole'
export const sexPenisField = 'sexPenis'
export const sexBreastField = 'sexBreast'
export const sponsorField = 'sponsor'
export const sexExcitationField = 'sexExcitation'
export const sexPriorityField = 'sexPriority'
export const periodField = 'period'
export const withPhotoField = 'withPhoto'

export const singleSelectField = [withPhotoField, periodField]

export const fields = [
  lookForField,
  'ageFrom',
  'ageTo',
  'country',
  'region',
  'city',
  'metro',
  targetField,
  signField,
  whoAreNearField,
  interestsField,
  withPhotoField,
  'withCompatibleSign',
  'periodType',
  periodField,
  'replyRate',
  childrenField,
  'weightFrom',
  'weightTo',
  'weightUnit',
  'heightFrom',
  'heightTo',
  'heightUnit',
  educationField,
  constitutionField,
  orientationField,
  raceField,
  languageApiField,
  smokeField,
  drinkField,
  circumstanceField,
  homeField,
  sexOftenField,
  sexRoleField,
  sexPenisField,
  sexBreastField,
  sponsorField,
  sexExcitationField,
  sexPriorityField,
  'limit',
  'offset',
  'navChanged',
  'navOid',
  'navActive',
  'isExtended',
  'isRestored',
  locationField,
  'requestSearchSource',
]
